.sphere-typography {
  font-weight: 400;
}

.sphere-typography--h1.sphere-typography--h1 {
  font-size: 3.5rem;
  font-weight: 600;
}

.sphere-typography--h2.sphere-typography--h2 {
  font-size: 2.75rem;
  font-weight: 600;
}

.sphere-typography--h3.sphere-typography--h3 {
  font-size: 2.375rem;
  font-weight: 600;
}

.sphere-typography--h4.sphere-typography--h4 {
  font-size: 2rem;
  font-weight: 600;
}

.sphere-typography--h5.sphere-typography--h5 {
  font-size: 1.75rem;
  font-weight: 600;
}

.sphere-typography--h6.sphere-typography--h6 {
  font-size: 1.5rem;
  font-weight: 600;
}

.sphere-typography--subhead1 {
  font-size: 1.125rem;
}

.sphere-typography--subhead2 {
  font-size: 1rem;
}

.sphere-typography--lg {
  font-size: 1rem;
}

.sphere-typography--base {
  font-size: 0.875rem;
}

.sphere-typography--sm {
  font-size: 0.75rem;
}

.sphere-typography--nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
